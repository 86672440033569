






















































































































































import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import 'quill/dist/quill.snow.css'
import {Component, Vue, Watch} from 'vue-property-decorator';
import {CategoryService, CategoryItem} from '../../store/category/CategoryService';
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import i18n from "@/i18n";
import {Category} from '@/models/category/category';
// import * as _ from 'lodash';
import filter from 'lodash/filter';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import {OptionItem} from "@/models/category/category";
import {ActionResult} from '@/models/ActionResult';
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";

extend('required', {
    ...required,
    message: (_: string, values: any) => i18n.t('validations.required', [_])
} as any);

@Component({
    components: {
        VuePerfectScrollbar,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        UserSuggestion
    }
})

export default class CategoryPage extends Vue {
    currentPage: number = 1;
    showThread: boolean = false;
    categories: Array<Category> = new Array<Category>();
    totalPages = 0;
    pageSize = 15;
    keyword: string = '';
    typeSearch: string = '';
    private categoryService: CategoryService;
    backgroundLoading: string = 'primary';
    colorLoading: string = '#fff';
    windowWidth: number = 1000;
    isEmailSidebarActive: boolean = false;
    clickNotClose: boolean = false;
    activeType: number | null = null;
    selected: any = [];
    activePrompt: boolean = false;
    debounce: any = null;
    settings: any = {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30,
    };
    types: Array<CategoryItem> = [];
    CategoryTypes: Array<CategoryItem> = this.types;
    isAdd: boolean = true;
    cate: Category = new Category();
    currentCate: any = null;
    description: string = '';
    invalid: boolean = false;
    popupActive: boolean = false;

    constructor() {
        super();

        this.categoryService = new CategoryService();
    }

    async getTypes() {
        var items = await this.categoryService.getTypes();

        if (items != null) {
            this.types = items;
            this.CategoryTypes = items;
        }
    }

    onSelect(value: any) {
        this.categoryService.updateManager({
            id: value[0].id,
            categoryId: this.cate.id,
            categoryName: this.cate.name,
            fullName: value[0].text,
            userName: value[0].description,
            avatar: value[0].avatar
        });
        this.cate.managerId = value[0].id;
        this.cate.fullName = value[0].text;
        this.cate.avatar = value[0].avatar;
        this.popupActive = false;
    }

    beforeMount() {
    }

    created() {
        this.setSidebarWidth()
    }

    getTypeName(type: number) {
        return new OptionItem(type).name;
    }

    mounted() {
        this.search(null);
        this.getTypes();
    }

    openPopup(item: any) {
        this.popupActive = true;

        this.cate = item;
    }

    fillterType() {
        let key = this.typeSearch.toUpperCase();
        this.CategoryTypes = filter(this.types, function (item: CategoryItem) {
            return item.name.toUpperCase().indexOf(key) >= 0 || item.normalizedName.toUpperCase().indexOf(key) >= 0;
        })
    }

    setUpdate(item: Category) {
        this.isAdd = false;
        this.cate = new Category();
        this.cate.id = item.id;
        this.cate.name = item.name;
        this.cate.description = item.description;
        this.cate.type = item.type;
        this.cate.objType = find(this.types, function (record: OptionItem) {
            return record.id == item.type;
        });
        this.currentCate = item;
        this.activePrompt = true;
    }

    @Watch('keyword')
    onKeyWordInputChange(value: string, oldValue: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.debounce = debounce(() => {
            this.currentPage = 1;
            this.search(this.activeType);
        }, 500);
        this.debounce();
    }

    @Watch('currentPage')
    onCurrentPageChange(value: number, oldValue: number) {
        this.currentPage = value;
        this.search(this.activeType);
    }

    @Watch('isSidebarActive')
    onIsSidebarActiveChange(value: string, oldValue: string) {
        if (!value) this.showThread = false
    }

    @Watch('windowWidth')
    onwindowWidthChange(value: string, oldValue: string) {
        this.setSidebarWidth()
    }

    @Watch('typeSearch')
    onKeyTypeSearchChange(value: string, oldValue: string) {
        this.fillterType();
    }

    setSidebarWidth() {
        if (this.windowWidth < 992) {
            this.isEmailSidebarActive = this.clickNotClose = false
        } else {
            this.isEmailSidebarActive = this.clickNotClose = true
        }
    }

    showAddForm() {
        this.activePrompt = true;
        this.isAdd = true;
        this.cate = new Category();
        this.cate.type = this.activeType;
    }

    async changeActive(item: any) {
        const result = await this.categoryService.changeActive(item.id, item.isActive);

        if (result.code > 0) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Lưu thành công",
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
        }
        // else {
        //     console.log(result);
        // }
    }

    async saveManager() {
    }

    async save() {
        if (this.cate.type == null) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Chọn loại danh mục",
                color: 'danger',
                iconPack: 'feather',
                icon: 'icon-warning'
            });
            return;
        }

        if (this.cate.name == undefined || this.cate.name.length == 0) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Tên không được để trống",
                color: 'danger',
                iconPack: 'feather',
                icon: 'icon-warning'
            });
            return;
        }

        this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: "#btnSave",
            scale: 0.45
        })

        if (this.isAdd) {
            await this.categoryService.insert(this.cate).then((result: ActionResult<string>) => {
                if (result.code > 0) {
                    //this.activePrompt = false;
                    this.cate.name = '';
                    this.search(this.activeType);
                }

                this.$vs.notify({
                    title: "Thông báo", text: result.message, color: (result.code > 0 ? 'primary' : 'danger'),
                    iconPack: 'feather', icon: 'icon-info'
                });

                setTimeout(() => {
                    this.$vs.loading.close("#btnSave > .con-vs-loading")
                }, 200);
            }).catch((error: any) => {
                this.$vs.notify({
                    title: "Thông báo", text: error.message, color: 'danger',
                    iconPack: 'feather', icon: 'icon-info'
                });

                setTimeout(() => {
                    this.$vs.loading.close("#btnSave > .con-vs-loading")
                }, 200);
            });

        } else {
            await this.categoryService.update(this.cate).then((result: ActionResult<string>) => {
                if (result.code > 0) {
                    this.activePrompt = false;
                    this.currentCate.name = this.cate.name;
                    this.currentCate.description = this.cate.description;
                    this.currentCate.type = this.cate.type;
                }

                this.$vs.notify({
                    title: "Thông báo", text: result.message, color: (result.code > 0 ? 'primary' : 'danger'),
                    iconPack: 'feather', icon: 'icon-info'
                });

                setTimeout(() => {
                    this.$vs.loading.close("#btnSave > .con-vs-loading")
                }, 200);
            }).catch((error: any) => {
                this.$vs.notify({
                    title: "Thông báo", text: error.message, color: 'danger',
                    iconPack: 'feather', icon: 'icon-info'
                });

                setTimeout(() => {
                    this.$vs.loading.close("#btnSave > .con-vs-loading")
                }, 200);
            });
        }
    }

    openConfirm(item: any) {
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `Xác nhận`,
            text: `Bạn có chắc chắn muốn xóa danh mục "${item.name}" không?`,
            accept: this.delete
        })

        this.currentCate = item;
    }

    selectTag(type: number) {
        this.currentPage = 1;
        this.search(type);
    }

    async delete() {
        let id = this.currentCate.id;
        const result = await this.categoryService.delete(id);

        this.$vs.notify({
            title: "Thông báo", text: result.message, color: (result.code > 0 ? 'primary' : 'danger'),
            iconPack: 'feather', icon: 'icon-info'
        });

        this.categories = filter(this.categories, function (obj) {
            return obj.id != id;
        });
    }

    openLoading() {
        this.$vs.loading();
    }

    beginSearch() {
        if (this.keyword == undefined || this.keyword.length < 2) {
            return;
        }

        this.search(this.activeType);
    }

    private async search(id: number | null) {
        this.activeType = id;
        this.openLoading();
        const result = await this.categoryService.search(this.keyword, this.activeType, this.currentPage, this.pageSize);

        if (result.code > 0) {
            this.categories = result.items;
            this.setTotalPage(result.totalRows);
        }

        setTimeout(() => {
            this.$vs.loading.close()
        }, 500);
    }

    private setTotalPage(totalRows: number) {
        if (totalRows == 0) {
            this.totalPages = 0;
        }
        if (totalRows <= this.pageSize) {
            this.totalPages = 1;
        }
        this.totalPages = Math.ceil((totalRows / this.pageSize));
    }
}
